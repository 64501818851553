import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Empty } from 'antd'
import { sumex } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendBarChartsOption } from './util'
import { sleep } from '@/common/util'
import { divisionOption } from '@/common/vars'
import { formatNum } from '@/common/util'
export default function TrendDivisionAndYear(props) {
  const {
    fields = 'cost',
    title,
    setShowEmpty,
    division,
    ym,
    axis,
    unit,
    media,
    dataViewHeaderFirstTitle,
  } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)
  const [lastres, setlastres] = useState(null)
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  /**
   * catogeryname 区分的类别名  比如 fa fbp
   * valname 具体显示的值 比如budget
   */
  const formatBarData = (data, ym, unit) => {
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const [slectm, selecty] = ym.split('-')

    // let startindex = data.findIndex((i) => i.month === +month - 1 && i.year === +year - 2)
    // let lastindex = data.findIndex((i) => i.month === +month && i.year === +year)
    // if (lastindex < 0) lastindex = data.length - 1
    // if (startindex < 0) startindex = 0

    //data = data.slice(startindex)
    //data = data.slice(0, lastindex + 1)
    data = data.slice(0, 24).sort((a, b) => {
      return a.year - b.year || a.month - b.month
    })

    const xData = []
    const yData1 = []
    const yData2 = []
    data.forEach((item) => {
      let { month: m, year: y, searchindex, yoy } = item
      yoy = yoy.toFixed(2)
      xData.push(`${map[m]}-${y.toString().slice(2)}`)
      if (slectm == m && selecty == y) {
        yData1.push({
          value: formatNum(searchindex, unit, 0),
          itemStyle: {
            color: '#a90000',
          },
        })
        yData2.push({
          value: yoy || 40,
          itemStyle: {
            color: '#a90000',
          },
        })
      } else {
        yData1.push(formatNum(searchindex, unit, 0))
        yData2.push(yoy || 20)
      }
    })

    return {
      xData,
      yData1,
      yData2,
      // ynames,
      dataViewHeaderFirstTitle,
    }
  }
  const [init, setinit] = useState(false)
  useEffect(() => {
    if (!ym || !media.length) return
    const [month, year] = ym.split('-')
    setinit(true)
    if (!year || !month) return
    sumex({
      fields: ['year', 'month', 'division', 'category', 'brand', 'searchindex'],
      title: '折柱图',
      groups: ['year', 'month', 'division', 'category'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: [+year, +year - 1, +year - 2, +year - 3],
        },
        {
          field: 'month',
          operator: 'IN',
          values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },

        {
          field: 'category',
          operator: 'IN',
          values: [axis],
        },
        {
          field: 'media',
          operator: 'IN',
          values: media,
        },
        {
          field: 'division',
          operator: 'IN',
          values: division.includes('all') ? divisionOption : division,
        },
      ],
      sorts: [
        {
          field: 'year',
          type: 'DESC',
        },
        {
          field: 'month',
          type: 'ASC',
        },
      ],
    }).then((res_) => {
      if (res_?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }

      let res = res_.sort((a, b) => {
        return b.year - a.year || b.month - a.month
      })

      setlastres(res)
      // debugger
      const formatdata = formatBarData(res, ym, unit) //formatBarData(res, month, year, unit)

      updateCharts(getTrendBarChartsOption(formatdata, unit))
    })
  }, [ym, media, unit])
  useEffect(() => {
    if (!lastres) return
    const formatdata = formatBarData(lastres, ym, unit)
    updateCharts(getTrendBarChartsOption(formatdata, unit))
  }, [ym, media, unit])

  return (
    // <div className="trend-wrap">
    //   <div ref={trend} style={{ width: '100%', height: '320px' }}></div>
    // </div>
    <div className="trend-wrap">
      <div className="title">
        <div>{title}</div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          position: 'relative',
          width: '100%',
          height: '360px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
