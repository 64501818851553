import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Radio, Select } from 'antd'
import { getPerformanceSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getMonthTrendBarChartsOption2 } from './util'
import { formatNum, numberWithCommas } from '@/common/util'

import { Empty } from 'antd'

export default function Crossbar4(props) {
  const {
    division,
    years,
    unit,
    title,
    category,
    productLine,
    campaignname,
    channel,
    media,
    mediaformat,
    get,
    months,
    yearsopt,
    setmonths,
    setyears,
    field1,
    asset,
  } = props
  const [group, setGroup] = useState('month')
  const [field, setfield] = useState('ctr')
  const radio2Option = [
    {
      label: 'Month',
      value: 'month',
    },
    {
      label: 'Year',
      value: 'year',
    },
  ]
  const fieldOpt = [
    {
      label: 'Impression',
      value: 'impression',
      tag: [
        'all',
        'WeChat Moments RTB',
        'WeChat Channel Feeds',
        'Red Feeds',
        'Union Pay Programmatic',
        'Baidu App Opening Page',
      ],
    },
    {
      label: 'Click',
      value: 'click',
      tag: [
        'all',
        'WeChat Moments RTB',
        'WeChat Channel Feeds',
        'Red Feeds',
        'Union Pay Programmatic',
        'Baidu App Opening Page',
      ],
    },
    {
      label: 'Traffic',
      value: 'traffic',
      tag: ['all', 'WeChat Moments RTB', 'WeChat Channel Feeds', 'Red Feeds'],
    },
    {
      label: 'Order',
      value: 'order',
      tag: ['all', 'WeChat Moments RTB', 'WeChat Channel Feeds', 'Red Feeds'],
    },
    {
      label: 'Revenue',
      value: 'revenue',
      tag: ['all', 'WeChat Moments RTB', 'WeChat Channel Feeds', 'Red Feeds'],
    },
    {
      label: 'Cost',
      value: 'cost',
      tag: [
        'all',
        'WeChat Moments RTB',
        'WeChat Channel Feeds',
        'Red Feeds',
        'Union Pay Programmatic',
        'Baidu App Opening Page',
      ],
    },
    {
      label: 'CTR',
      value: 'ctr',
      tag: [
        'all',
        'WeChat Moments RTB',
        'WeChat Channel Feeds',
        'Red Feeds',
        'Union Pay Programmatic',
        'Baidu App Opening Page',
      ],
    },
    {
      label: 'CPM',
      value: 'cpm',
      tag: ['Union Pay Programmatic', 'Baidu App Opening Page'],
    },
    {
      label: 'Engagement-Wechat',
      value: 'engagementwechat',
      tag: ['WeChat Moments RTB', 'WeChat Channel Feeds'],
    },
    {
      label: 'ER-Wechat',
      value: 'er_wechat',
      tag: ['WeChat Moments RTB', 'WeChat Channel Feeds'],
    },
    {
      label: 'YS T+0 Revenue',
      value: 't0ysrevenue',
      tag: ['WeChat Moments RTB'],
    },
    {
      label: 'YS T+7 Revenue',
      value: 't7ysrevenue',
      tag: ['WeChat Moments RTB'],
    },
    {
      label: 'YS T+15 Revenue',
      value: 't15ysrevenue',
      tag: ['WeChat Moments RTB'],
    },
    {
      label: 'YS T+0 ROI',
      value: 't0ysrevenuema',
      tag: ['WeChat Moments RTB'],
    },
    {
      label: 'YS T+7 ROI',
      value: 't7ysrevenuema',
      tag: ['WeChat Moments RTB'],
    },
    {
      label: 'YS T+15 ROI',
      value: 't15ysrevenuema',
      tag: ['WeChat Moments RTB'],
    },
    {
      label: 'Engagement-POST',
      value: 'engagementpost',
      tag: ['Red Feeds'],
    },
    {
      label: 'ER-POST',
      value: 'er_post',
      tag: ['Red Feeds'],
    },
    {
      label: 'ROI',
      value: 'roi',
      tag: ['WeChat Moments RTB'],
    },
    {
      label: 'AOV',
      value: 'aov',
      tag: ['WeChat Moments RTB'],
    },
    {
      label: 'CPF',
      value: 'cpf',
      tag: ['WeChat Channel Feeds'],
    },
    {
      label: '100%vv',
      value: 'vv100',
      tag: ['WeChat Channel Feeds'],
    },
    {
      label: '5s VV%',
      value: 'vv5s',
      tag: ['WeChat Channel Feeds'],
    },
  ]
  const capitalizeFirstLetter = (str) => {
    return fieldOpt.filter((it) => {
      return it.value === str
    })[0]?.label
  }

  let checkGrouop
  const changePieName = (val) => {
    let ym = val.split('-')
    checkGrouop = val === checkGrouop ? undefined : val
    if (ym.length === 2) {
      setyears([checkGrouop ? Number('20' + ym[1]) : yearsopt[0]])
      setmonths([checkGrouop ? ym[0] : 'all'])
    } else if (ym.length === 1) {
      setyears([checkGrouop ? Number(ym) : yearsopt[0]])
    }
  }
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend, { changePieName })
  const map = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const specialNum = [
    'ctr',
    'er_wechat',
    't0ysrevenuema',
    't7ysrevenuema',
    't15ysrevenuema',
    'er_post',
    'roi',
    'vv100',
    'vv5s',
  ]
  useEffect(() => {
    setfield('ctr')
  }, [channel])
  useEffect(() => {
    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Paid Search'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i) + 1),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!channel.includes('all'))
      filters.push({
        field: 'mediachannel',
        operator: 'IN',
        values: channel,
      })
    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })
    getPerformanceSum({
      title: '折线柱图',

      fields: [field1, field],
      groups: group === 'month' ? ['year', 'month'] : ['year'],
      filters,
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }

      let year = years.includes('all') ? yearsopt : years
      year = year.sort(function (a, b) {
        return a - b
      })

      const xAxisData = []
      const ydata = [
        { name: capitalizeFirstLetter(field1), data: [] },
        { name: capitalizeFirstLetter(field), data: [] },
      ] //res
      year.forEach((y) => {
        if (group === 'month') {
          map.forEach((m) => {
            xAxisData.push(m + '-' + (y - 2000))
            let y_it = res.filter((it) => {
              return it.year === y && it.month === m
            })
            ydata[0].data.push(
              specialNum.includes(field1)
                ? (y_it[0]?.[field1] || 0).toFixed(2)
                : formatNum(y_it[0]?.[field1] || 0, unit, 0)
            )
            ydata[1].data.push(
              specialNum.includes(field)
                ? (y_it[0]?.[field] || 0).toFixed(2)
                : formatNum(y_it[0]?.[field] || 0, unit, 0)
            )
          })
        } else {
          xAxisData.push(y)
          let y_it = res.filter((it) => {
            return it.year === y
          })
          ydata[0].data.push(
            specialNum.includes(field1)
              ? (y_it[0]?.[field1] || 0).toFixed(2)
              : formatNum(y_it[0]?.[field1] || 0, unit, 0)
          )
          ydata[1].data.push(
            specialNum.includes(field)
              ? (y_it[0]?.[field] || 0).toFixed(2)
              : formatNum(y_it[0]?.[field] || 0, unit, 0)
          )
        }
      })

      let d = getMonthTrendBarChartsOption2(
        { xData: xAxisData, yData: ydata, specialNum, field, field1 },
        unit,
        group === 'year' ? 'Year' : 'Month'
      )
      updateCharts(d)
    })
  }, [get, years, months, unit, group, field1, field])

  return (
    <div className="trend-wrap">
      <div className="title">
        {capitalizeFirstLetter(field1) + ' & ' + capitalizeFirstLetter(field) + title}
      </div>
      <div className="tool">
        <div style={{ width: '170px' }}>
          <Radio.Group
            options={radio2Option}
            onChange={(val) => {
              setGroup(val.target.value)
            }}
            value={group}
            size="small"
            optionType="button"
          />
        </div>
        <div>
          <Select
            options={fieldOpt.filter((i) => {
              return channel.length == 1 ? i.tag.includes(channel[0]) : i.tag.includes('all')
            })}
            size="small"
            value={field}
            onChange={(val) => {
              setfield(val)
            }}
            style={{ width: '240px' }}
          />
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          width: 'calc(100%)',
          height: '280px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
