import Card from '../../components/Card'
import { useState, useEffect } from 'react'
import TrendDivisionAndYear from './trendDivisionAndYear'
import { connect } from 'react-redux'
import styles from './index.module.less'
import { Radio, Select, Checkbox, Button } from 'antd'
import CrossFilter from '@/components/CrossFilter'
import { getSum1_2, getSum2, downTable } from '@/api/home'
import Scatters from './scatters'
import CostTable from './costTable1'
import TaTable from './taTable'
import TrendPie2 from './trend-pie2'

import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { themecolor_pie1 } from '@/common/vars'
import Crossbar4 from './Crossbar4'
import Crossbar3 from './Crossbar3'
import TrendLine from './trend-line'
import { store } from '@/redux'
import { setDivision, setUnit } from '@/redux/actions'

const { wrap } = styles
const cardstyle = {
  marginTop: '-16px',
}

const mediaGroupList = {
  Tencent: { mediaFormat: ['Moments RTB', 'Channel Feeds'] },
  Baidu: { mediaFormat: ['App Opening Page'] },
  Red: { mediaFormat: ['RTB Feeds', 'GD Feeds'] },
  'Union Pay Programmatic': { mediaFormat: ['App Opening Page'] },
}
const Budget = (props) => {
  const { currentYear, unit, setUnit, yearsopt, collapsed } = props
  const [ho, setHo] = useState(1)
  const [get, setGet] = useState(1)
  const resetting = (val) => {
    setmonths(['all'])
    setCategory(['all'])
    setProductLine(['all'])
    setDivision(['all'])
    setMedia(['all'])
    setMediaformat(['all'])
    setChannel(['all'])
    setMediagroup(['all'])
    setCampaignname(['all'])
    setAsset(['all'])
    setyears([currentYear])
    setOptList(true)
  }
  const [checkMedia, setcheckMedia] = useState()
  const [checkMediaGroup, setcheckMediaGroup] = useState()

  const [years, setyears] = useState([currentYear])
  const [months, setmonths] = useState(['all'])
  const [updatetime, setupdatetime] = useState('')

  const [radioOpt, setradioOpt] = useState([])
  const [radioVal, setradioVal] = useState('all')

  const [productLine, setProductLine] = useState(['all'])
  const [productLineOpt, setProductLineOpt] = useState([])

  const [category, setCategory] = useState(['all'])
  const [categoryOpt, setCategoryOpt] = useState([])

  const [asset, setAsset] = useState(['all'])
  const [assetOpt, setAssetOpt] = useState([])

  const [mediagroup, setMediagroup] = useState(['all'])
  const [mediagroupOpt, setMediagroupOpt] = useState([])

  const [media, setMedia] = useState(['all'])
  const [mediaOpt, setMediaOpt] = useState([])

  const [mediaformat, setMediaformat] = useState(['all'])
  const [mediaformatOpt, setMediaformatOpt] = useState([])

  const [channel, setChannel] = useState(['all'])
  const [channelOpt, setChannelOpt] = useState([])

  const [campaignname, setCampaignname] = useState(['all'])
  const [campaignnameOpt, setCampaignnameOpt] = useState([])

  const [division, setDivision] = useState(['all'])
  const [divisionopt, setdivisionOpt] = useState([])
  const [scattersGroup, setScattersGroup] = useState('campaignname')
  const [crossbar4Field1, setCrossbar4Field1] = useState('impression')
  const onRadioChange = ({ target: { value } }) => {
    setradioVal(value)
  }
  const handleChange = (val, fun) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) {
      setHo(val)
      return fun(['all'])
    }
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) {
      setHo(val)
      return fun(val.slice(1))
    } //由默认转为其他
    if (index === val.length - 1 && val.length > 0) {
      setHo(val)
      return fun(['all'])
    } //由其他转为默认
    setHo(val)
    return fun(val)
  }

  const getOptLine = (val, fn) => {
    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Paid Search'],
      },
      {
        field: val,
        operator: 'NOT_IN',
        values: ['', ' ', '-'],
      },
    ]

    if (!mediaformat.includes('all') && val != 'mediaformat')
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!mediagroup.includes('all') && val != 'mediagroup')
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediagroup,
      })
    if (!media.includes('all') && val != 'media')
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all') && val != 'productline')
      filters.push({
        field: 'productline',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all') && val != 'campaignname')
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all') && val != 'divisioncorp')
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all') && val != 'categorycorp')
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    getSum1_2({
      groups: [val],
      filters: filters,
      sorts: [
        {
          field: val,
          type: 'ASC',
        },
      ],
    }).then((res) => {
      fn([
        {
          label: '全部',
          value: 'all',
        },
        ...res.map((i) => ({ label: i[val], value: i[val] })),
      ])
    })
  }
  const getUpdateTime = () => {
    getSum1_2({
      title: 'updateTime',
      fields: ['maxyearandmonth'],
      groups: ['year'],
      sorts: [{ field: 'maxyearandmonth', type: 'DESC' }],
    }).then((res) => {
      setupdatetime(res[0].maxyearandmonth)
    })
  }

  const setOptList = (reset) => {
    getOptLine('categorycorp', setCategoryOpt)
    getOptLine('productline', setProductLineOpt)
    getOptLine('campaignname', setCampaignnameOpt)
    //  getOptLine('mediagroup', setMediagroupOpt)
    getOptLine('media', setMediaOpt)
    getOptLine('mediaformat', setMediaformatOpt)
    getOptLine('mediachannel', setChannelOpt)
    getOptLine('divisioncorp', setdivisionOpt)
    getOptLine('assets', setAssetOpt)
    setGet(get + 1)
  }

  useEffect(() => {
    getUpdateTime()
  }, [])

  useEffect(() => {
    setOptList()
  }, [ho])
  const setMG = (val) => {
    setcheckMediaGroup(val)
    setradioOpt(mediaGroupList[val] ? mediaGroupList[val].mediaFormat : [])
  }
  return (
    <div className={wrap}>
      <CrossFilter
        updatetime={updatetime + '，本页面不包含Paid Search数据。'}
        unit={unit}
        resetting={resetting}
        division={division}
        setDivision={setDivision}
        years={years}
        setyears={setyears}
        months={months}
        setmonths={setmonths}
      >
        <div className="toolLine">
          <div className="label">Media Channel</div>
          <Select
            maxTagCount={1}
            options={channelOpt}
            value={channel}
            onChange={(val) => {
              handleChange(val, setChannel)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
        <div className="toolLine">
          <div className="label">Media Format</div>
          <Select
            maxTagCount={1}
            options={mediaformatOpt}
            value={mediaformat}
            onChange={(val) => {
              handleChange(val, setMediaformat)
            }}
            blur={(val) => {
              console.log(123, val)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>

        {/* <div className="toolLine">
          <div className="label">MediaGroup</div>
          <Select
            maxTagCount={1}
            options={mediagroupOpt}
            value={mediagroup}
            onChange={(val) => {
              handleChange(val, setMediagroup)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div> */}

        <div className="toolLine">
          <div className="label">Media</div>
          <Select
            maxTagCount={1}
            options={mediaOpt}
            value={media}
            onChange={(val) => {
              handleChange(val, setMedia)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>

        <div className="toolLine">
          <div className="label">Product Line</div>
          <Select
            maxTagCount={1}
            options={productLineOpt}
            value={productLine}
            onChange={(val) => {
              handleChange(val, setProductLine)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>

        <div className="toolLine">
          <div className="label">Campaign</div>
          <Select
            maxTagCount={1}
            options={campaignnameOpt}
            value={campaignname}
            onChange={(val) => {
              handleChange(val, setCampaignname)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
        <div className="toolLine">
          <div className="label">Division</div>
          <Select
            maxTagCount={1}
            options={divisionopt}
            value={division}
            onChange={(v) => {
              handleChange(v, setDivision)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
        <div className="toolLine">
          <div className="label">Category</div>

          <Select
            maxTagCount={1}
            options={categoryOpt}
            value={category}
            onChange={(val) => {
              handleChange(val, setCategory)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
        <div className="toolLine">
          <div className="label">Asset</div>

          <Select
            maxTagCount={1}
            options={assetOpt}
            value={asset}
            onChange={(val) => {
              handleChange(val, setAsset)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
      </CrossFilter>
      <Card title="" style={cardstyle}>
        <div className="line" style={{ marginBottom: '22px' }}>
          {/* <div className="line-1-left ">
            <TrendPie2
              title="Cost By Media "
              get={get}
              setMediagroup={(val) => {
                handleChange(val, setMediagroup)
              }}
              asset={asset}
              mediagroupOpt={mediagroupOpt}
              mediagroup={mediagroup}
              mediaOpt={mediaOpt}
              mediaformat={mediaformat}
              mediaformatOpt={mediaformatOpt}
              media={media}
              division={division}
              unit={unit}
              years={years}
              months={months}
              yearsopt={yearsopt}
              category={category}
              categoryOpt={categoryOpt}
              productLine={productLine}
              productLineOpt={productLineOpt}
              campaignname={campaignname}
              campaignnameOpt={campaignnameOpt}
            ></TrendPie2>
          </div> */}
          <div className="line-1-right">
            <div class="title">Digital Performance</div>
            <CostTable
              get={get}
              asset={asset}
              months={months}
              // mediagroupOpt={mediagroupOpt}
              // mediagroup={mediagroup}
              mediaOpt={mediaOpt}
              media={media}
              channel={channel}
              channelOpt={channelOpt}
              setChannel={(val) => {
                handleChange(val, setChannel)
              }}
              mediaformat={mediaformat}
              mediaformatOpt={mediaformatOpt}
              unit={unit}
              division={division}
              years={years}
              yearsopt={yearsopt}
              category={category}
              categoryOpt={categoryOpt}
              productLine={productLine}
              productLineOpt={productLineOpt}
              campaignname={campaignname}
              campaignnameOpt={campaignnameOpt}
            ></CostTable>
          </div>
        </div>
      </Card>

      <Card title="" style={cardstyle}>
        <div className="compaign-content">
          <div className="compaign-right">
            <Crossbar3
              asset={asset}
              months={months}
              get={get}
              unit={unit}
              dataViewHeaderFirstTitle="Media Group"
              division={division}
              yearsopt={yearsopt}
              years={years}
              category={category}
              productLine={productLine}
              setProductLine={(val) => {
                handleChange(val, setProductLine)
              }}
              campaignname={campaignname}
              setCampaignname={(val) => {
                handleChange(val, setCampaignname)
              }}
              setField1={setCrossbar4Field1}
              mediaformat={mediaformat}
              media={media}
              channel={channel}
              style={{ width: '100%' }}
              color={themecolor_pie1}
            ></Crossbar3>
          </div>

          <div className="compaign-right">
            <Crossbar4
              asset={asset}
              title=" By Month"
              months={months}
              field1={crossbar4Field1}
              get={get}
              unit={unit}
              dataViewHeaderFirstTitle="Media Group"
              division={division}
              yearsopt={yearsopt}
              years={years}
              category={category}
              productLine={productLine}
              campaignname={campaignname}
              mediaformat={mediaformat}
              media={media}
              mediaGroup={mediagroup}
              channel={channel}
              style={{ width: '100%' }}
              color={themecolor_pie1}
              setmonths={setmonths}
              setyears={setyears}
            ></Crossbar4>
          </div>
        </div>
      </Card>
      <Card title="" style={cardstyle}>
        <div className="line" style={{ marginBottom: '22px' }}>
          <div className="line-1-left">
            <Scatters
              title=" &Cost"
              asset={asset}
              unit={unit}
              get={get}
              channel={channel}
              setScattersGroup={setScattersGroup}
              months={months}
              dataViewHeaderFirstTitle="Media Group"
              division={division}
              years={years}
              category={category}
              productLine={productLine}
              campaignname={campaignname}
              mediaformat={mediaformat}
              media={media}
              mediaGroup={mediagroup}
              style={{ width: '100%' }}
              color={themecolor_pie1}
              setProductLine={(val) => {
                handleChange(val, setProductLine)
              }}
              setCampaignname={(val) => {
                handleChange(val, setCampaignname)
              }}
              setAsset={(val) => {
                handleChange(val, setAsset)
              }}
            ></Scatters>
          </div>
          <div className="line-1-right">
            <TaTable
              get={get}
              unit={unit}
              scattersGroup={scattersGroup}
              asset={asset}
              channel={channel}
              division={division}
              yearsopt={yearsopt}
              months={months}
              years={years}
              category={category}
              productLine={productLine}
              campaignname={campaignname}
              mediaformat={mediaformat}
              media={media}
              mediaGroup={mediagroup}
            ></TaTable>
          </div>
        </div>
      </Card>
    </div>
  )
}
export default connect((state) => ({
  yearsopt: state.years.slice().reverse(),
  currentYear: state.years[state.years.length - 1],
  unit: state.unit,
  // preYear: state.years[state.years.length - 2],
}))(Budget)
