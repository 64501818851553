import { dataViewConfigCreator, numberWithCommas } from '@/common/util'
import { themecolor, themecolor_two } from '@/common/vars'
import { formatUnit } from '@/common/util'
export const formatmonth = (i) => {
  const monthname = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return monthname[i]
}

export const getMonthTrendBarChartsOption = ({ xAxisData, series }, unit) => {
  let seriesData = []
  for (let keyname in series) {
    seriesData.push({
      name: keyname,
      type: 'bar',
      //   stack: 'ad',
      barWidth: '12',
      emphasis: {
        focus: 'series',
      },
      data: series[keyname],
    })
    seriesData.reverse()
  }
  return {
    color: themecolor_two,
    legend: { right: '50' }, //颜色提示
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = opt.xAxis[0].data
          var series = opt.series
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Month</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series.map((j) => `<td>${numberWithCommas(j.data[i] || 0)}</td>`).join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: formatUnit(unit) || '',
      },
      {
        type: 'value',
      },
    ],
    series: seriesData,
  }
}

export const getMonthTrendBarChartsOption2 = ({ xAxisData, barseries, lineseries, type }, unit) => {
  // barseries.sort((a,b)=>{
  //   if(a.)
  // })
  let seriesData1 = []
  let seriesData2 = []
  for (let keyname in barseries) {
    seriesData1.push({
      name: keyname,
      type: 'bar',
      stack: 'ad',
      barMaxWidth: '12',
      emphasis: {
        focus: 'series',
      },
      data: barseries[keyname],
    })
  }
  // v1.2 Paid Search需求放到最下面
  const index = seriesData1.findIndex((i) => i.name === 'Paid Search')
  if (index >= 0) {
    const delItem = seriesData1[index]
    seriesData1.splice(index, 1)
    seriesData1.unshift(delItem)
  }
  for (let keyname in lineseries) {
    const l_keyname = keyname.toLocaleLowerCase()
    seriesData1.push({
      name: keyname + ' Share',
      type: 'line',
      smooth: true,
      yAxisIndex: 1,
      emphasis: {
        focus: 'series',
      },
      data: lineseries[keyname],
      tooltip: {
        valueFormatter: function (value) {
          return Number(value || 0).toFixed(2) + ' %'
        },
      },
    })
  }
  // debugger
  return {
    color: themecolor,
    legend: { right: '50' }, //颜色提示
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = opt.xAxis[0].data
          var series = opt.series
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Month</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map(
                  (j, index) =>
                    `<td>${numberWithCommas(j.data[i] || 0)}${
                      index === series.length - 1 && j.name.indexOf('Share') >= 0 ? '%' : ''
                    }</td>`
                )
                .join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    tooltip: {
      trigger: 'axis',
      // axisPointer: {
      //   type: 'shadow',
      // },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: formatUnit(unit),
        axisLabel: {
          formatter: '{value} ',
        },
      },
      {
        type: 'value',
        axisLabel: {
          formatter: '{value} %',
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: seriesData1,
  }
}

export const getTrendLineOption = ({
  preyeardata,
  curyeardata,
  preyear,
  curyear,
  field,
  formatpercent,
}) => {
  // const series1 = preyeardata.map((i) => i.si)
  // const series1 = new Array(12).fill(0)
  // preyeardata.forEach((i) => {
  //   const index = i.month - 1
  //   series1[index] = i[field]
  // })

  // debugger
  const series1 = preyeardata.map((i) => i[field])
  const series2 = curyeardata.map((i) => i[field])

  const xAxisData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => formatmonth(i))

  return {
    color: themecolor_two,
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const year = params[0].axisValueLabel
        let res = `${year}<br/>`
        let sum = 0
        params.forEach((i) => {
          const { seriesName, value, marker } = i
          res += `<div style="display:flex;width:100px;align-item:center;justify-content:space-between"><span>${marker}${seriesName}: </span><span style="display:inline-block;text-align:right;">${
            field !== 'cpc' ? value + '%' : value
          }</span> </div>`
        })
        return res
      },
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          console.log(opt)
          var axisData = opt.xAxis[0].data
          var series = opt.series
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Month</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map(
                  (j) =>
                    `<td>${
                      field !== 'cpc'
                        ? numberWithCommas(j.data[i] || 0) + '%'
                        : numberWithCommas(j.data[i] || 0)
                    }</td>`
                )
                .join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: {
      data: [preyear + '', curyear + ''],
      right: 25,
      top: 5,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    // toolbox: {
    // feature: {
    //   saveAsImage: {},
    // },
    // },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (val) => {
          return field !== 'cpc' ? val + '%' : val
        },
      },
    },
    series: [
      {
        name: curyear,
        type: 'line',
        data: series2,
        smooth: true,
      },
      {
        name: preyear,
        type: 'line',
        smooth: true,
        data: series1,
      },
    ],
  }
}

export const getTrendLineOption2 = ({
  preyeardata,
  curyeardata,
  preyear,
  curyear,
  field,
  formatpercent,
}) => {
  const series1 = new Array(12).fill(0)
  preyeardata.forEach((i) => {
    const index = i.month - 1
    series1[index] = i[field]
  })

  // debugger
  const series2 = curyeardata.map((i) => i[field])

  const xAxisData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => formatmonth(i))

  return {
    color: themecolor_two,
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const year = params[0].axisValueLabel
        let res = `${year}<br/>`
        let sum = 0
        params.forEach((i) => {
          const { seriesName, value, marker } = i
          res += `<div style="display:flex;width:100px;align-item:center;justify-content:space-between"><span>${marker}${seriesName}: </span><span style="display:inline-block;text-align:right;">${
            formatpercent ? value + '%' : value
          }</span> </div>`
        })
        return res
      },
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          console.log(opt)
          var axisData = opt.xAxis[0].data
          var series = opt.series
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Month</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map(
                  (j) =>
                    `<td>${
                      formatpercent
                        ? numberWithCommas(j.data[i] || 0) + '%'
                        : numberWithCommas(j.data[i] || 0)
                    }</td>`
                )
                .join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: {
      data: [preyear + '', curyear + ''],
      right: 25,
      top: 5,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    // toolbox: {
    // feature: {
    //   saveAsImage: {},
    // },
    // },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (val) => {
          return formatpercent ? val + '%' : val
        },
      },
    },
    series: [
      {
        name: curyear,
        type: 'line',
        data: series2,
        smooth: true,
      },
      preyeardata.length > 0
        ? {
            name: preyear,
            type: 'line',
            smooth: true,
            data: series1,
          }
        : undefined,
    ],
  }
}
