import { dataViewConfigCreator } from '@/common/util'
import { themecolor, themecolor_pie, themecolor_pie1, themecolor_two } from '@/common/vars'
import {
  contentToOption,
  formatUnit,
  formatNum,
  getLegendSelected,
  numberWithCommas,
} from '@/common/util'
import { divisionOption } from '@/common/vars'
export const formatMillion = (num) => {
  if (!num) return 0
  // debugger
  const res = (num / (1000 * 1000))?.toFixed(2)
  return parseFloat(res)
}
export const formatTableNum = (num) => {
  if (!num) return 0
  // if (num < 100000) {
  //   return (num / 1000)?.toFixed(2) + 'K'
  // }
  // return (num / (1000 * 1000))?.toFixed(2) + 'M'
  // debugg
  return numberWithCommas(parseInt(num))
}

export const addtablekey = (tree) => {
  const fn = (node, lv = 1) => {
    node.key = lv
    if (!node.children) return
    if (!node.children.length) return
    node.children.forEach((i, index) => {
      fn(i, lv + `-${index}`)
    })
  }
  tree.forEach((i, index) => {
    fn(i, index)
  })
}

export const formatmonth = (i) => {
  const monthname = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return monthname[i]
}
export const getTrendBarChartsOption = ({ xData, yData, ynames }, unit, firstTitle) => {
  let seriesData = yData.map((datai, index) => {
    return {
      name: ynames[index],
      type: 'bar',
      stack: 'ad',
      barWidth: '16',
      emphasis: {
        focus: 'series',
      },
      data: datai,
    }
  })

  return {
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = opt.xAxis[0].data // [1,2,3,4,5...12]
          var series = opt.series // [tencent,red,baidu,non-baidu]
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${firstTitle || 'month/budget'}</td>` +
            // axisData.map((i) => `<td>${i} Budegt</td>`).join('') +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          // const curyearsum = axisData.map((i, index) => {
          //   return series
          //     .map((j) => j.data[index])
          //     .reduce((pre, cur) => (pre += cur))
          //     ?.toFixed(1)
          // })
          // console.log(curyearsum)
          // debugger
          // let totalsum = 0
          for (var i = 0, l = axisData.length; i < l; i++) {
            // const sum = series[i].data.reduce((pre, cur) => (pre += cur))
            // totalsum += sum
            // const curyearsum = series.map(j=>j.data[i]).reduce((pre,cur)=>pre+=cur)
            // debugger
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map(
                  (j, jindex) =>
                    // `<td>${j}</td> <td>${((j * 100) / curyearsum[jindex])?.toFixed(1)}%</td>`
                    `<td>${numberWithCommas(j.data[i])}</td>`
                )
                .join('') +
              '</tr>'
          }
          // table +=
          //   '<tr>' +
          //   '<td>' +
          //   'Total' +
          //   '</td>' +
          //   curyearsum.map((i) => `<td>${i}</td>`).join('') +
          //   '</tr>'

          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: {
      right: '50',
      type: 'scroll',
      // selected:getLegendSelected(seriesData.map(i=>{ return i.name}),checkAllVal),
    }, //颜色提示
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const year = params[0].axisValueLabel
        let res = `${year}<br/>`
        let sum = 0
        params.forEach((i) => {
          const { seriesName, value, marker } = i
          sum += Number(value)

          //"<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>"
          res += `<div style="display:flex;width:150px;align-item:center;justify-content:space-between"><span>${marker}${seriesName}: </span><span style="display:inline-block;text-align:right;">${numberWithCommas(
            value
          )}</span> </div>`
        })
        res += `<div style="display:flex;width:150px;align-item:center;justify-content:space-between"><span style="font-weight: 700">Total: </span>${
          sum ? numberWithCommas(Number(sum.toFixed(0))) : '-'
        }</div>`

        return res
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        axisLabel: {
          //  X 坐标轴标签相关设置
          interval: 0,
          rotate: '45',
        },
      },
    ],
    yAxis: [
      {
        name: formatUnit(unit) + '(RMB)',
        type: 'value',
      },
    ],
    series: seriesData,
    color: themecolor_pie1,
  }
}
export const getTrendBarChartsOption2 = ({ xData, yData, group }, unit, firstTitle) => {
  let seriesData = yData.map((t, index) => {
    return {
      name: t.name,
      type: 'bar',
      stack: t.year,
      barWidth: '16',
      emphasis: {
        focus: 'series',
      },
      data: t.data.map((s) => {
        return { value: s, year: t.year }
      }),
    }
  })

  return {
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = opt.xAxis[0].data // [1,2,3,4,5...12]
          var series = opt.series // [tencent,red,baidu,non-baidu]

          // 表格头  先name再data
          var table =
            '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${firstTitle || 'month/budget'}</td>` +
            // axisData.map((i) => `<td>${i} Budegt</td>`).join('') +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          // const curyearsum = axisData.map((i, index) => {
          //   return series
          //     .map((j) => j.data[index])
          //     .reduce((pre, cur) => (pre += cur))
          //     ?.toFixed(1)
          // })
          // console.log(curyearsum)
          // debugger
          // let totalsum = 0
          for (var i = 0, l = axisData.length; i < l; i++) {
            // const sum = series[i].data.reduce((pre, cur) => (pre += cur))
            // totalsum += sum
            // const curyearsum = series.map(j=>j.data[i]).reduce((pre,cur)=>pre+=cur)
            // debugger
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map(
                  (j, jindex) =>
                    // `<td>${j}</td> <td>${((j * 100) / curyearsum[jindex])?.toFixed(1)}%</td>`
                    `<td>${numberWithCommas(j.data[i].value)}${formatUnit(unit)}</td>`
                )
                .join('') +
              '</tr>'
          }
          // table +=
          //   '<tr>' +
          //   '<td>' +
          //   'Total' +
          //   '</td>' +
          //   curyearsum.map((i) => `<td>${i}</td>`).join('') +
          //   '</tr>'

          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: {
      right: '50',
      type: 'scroll',
      // selected:getLegendSelected(seriesData.map(i=>{ return i.name}),checkAllVal),
    }, //颜色提示
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        let text = ''
        params.forEach((i, index) => {
          let { seriesName, marker, seriesIndex, value, axisValue, data } = i
          if (index == 0) text = axisValue
          text +=
            "<div style='display:flex;justify-content:space-between;width:300px'><div>" +
            marker +
            seriesName +
            ':</div><div>' +
            value +
            formatUnit(unit) +
            '</div></div>' //
        })
        return text
      },

      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        axisLabel: {
          //  X 坐标轴标签相关设置
          interval: 0,
          rotate: '45',
        },
      },
    ],
    yAxis: [
      {
        name: formatUnit(unit) + '(RMB)',
        type: 'value',
      },
    ],
    series: seriesData,
    color: themecolor_pie1,
  }
}
export const getMonthTrendBarChartsOption = (
  { xAxisData, series, color, dataViewHeaderFirstTitle, hor },
  unit
) => {
  let seriesData = []

  for (let keyname in series) {
    if (keyname != 'months') {
      seriesData.push({
        name: keyname,
        type: 'bar',
        stack: 'ad',
        barMaxWidth: '16',
        emphasis: {
          focus: 'series',
        },
        data: series[keyname],
      })
    }
  }

  let months = series['months']
  return {
    color: color,
    legend: { right: '50' }, //颜色提示
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const year = params[0].axisValueLabel
        let res = `${year}<br/>`
        let sum = 0

        params.forEach((i) => {
          let ind = i.dataIndex
          const { seriesName, value, marker } = i
          sum += Number(value)

          //"<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>"
          res += `<div style="display:flex;width:100px;align-item:center;justify-content:space-between"><span>${marker}${seriesName}: </span><span style="display:inline-block;text-align:right;">${numberWithCommas(
            value
          )}${formatUnit(unit)}</span> </div>`
          res += `<div style="width:250px;word-break:break-all"><div style="font-weight: 800">投放月份: 
          </div><div >${months[ind]
            .split(',')
            .map((it, i) => {
              return (i + 1) % 3 === 0
                ? it + '</br>'
                : it + (months[ind].split(',').length == i + 1 ? ' ' : ' , ')
            })
            .join('')}</div>   
          </div>`
        })
        res += `<div style="display:flex;width:100px;align-item:center;justify-content:space-between"><span style="font-weight: 700">Total: </span>${
          sum ? numberWithCommas(Number(sum.toFixed(0))) + formatUnit(unit) : '-'
        }</div>`

        return res
      },
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = hor ? opt.yAxis[0].data : opt.xAxis[0].data //['SubCore', 'RCC', ..]
          var series = opt.series //
          const seriesData = series[0].data
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${dataViewHeaderFirstTitle}</td>` +
            `<td>Budget</td>` +
            // `<td>总计</td>` +
            '</tr>'
          // 表格题  先name再data

          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              '<td>' +
              numberWithCommas(seriesData[i]) +
              formatUnit(unit) +
              '</td>' +
              '</tr>'
          }

          table += '</tbody></table>'
          return table
        }),
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '6%',
      containLabel: true,
    },
    xAxis: hor
      ? [
          {
            name: formatUnit(unit) + '(RMB)',
            type: 'value',
            nameLocation: 'middle',
            nameGap: 25,
          },
        ]
      : [
          {
            type: 'category',
            data: xAxisData,
          },
        ],
    yAxis: hor
      ? [
          {
            type: 'category',
            data: xAxisData,
          },
        ]
      : [
          {
            name: formatUnit(unit) + '(RMB)',
            type: 'value',
          },
        ],
    series: seriesData,
    dataZoom: [
      {
        type: 'slider',
        maxValueSpan: 9, //显示数据的条数(默认显示10个)
        minValueSpan: 9, //显示数据的条数(默认显示10个)
        show: true,
        yAxisIndex: [0],
        left: '93%', //滑动条位置
        start: 100, //默认为0
        end: 90, //默认为100
        orient: 'vertical',
        filterMode: 'empty',
        // zoomLock:true,
      },
      {
        type: 'inside', //内置滑动，随鼠标滚轮展示
        yAxisIndex: [0],
        zoomOnMouseWheel: false, //如何触发缩放。可选值为：true：表示不按任何功能键，鼠标滚轮能触发缩放。false：表示鼠标滚轮不能触发缩放。'shift'：表示按住 shift 和鼠标滚轮能触发缩放。'ctrl'：表示按住 ctrl 和鼠标滚轮能触发缩放。'alt'：表示按住 alt 和鼠标滚轮能触发缩放。。
        moveOnMouseMove: true,
        moveOnMouseWheel: true, //鼠标滚轮实现移动
      },
    ],
  }
}
export const getMonthTrendBarChartsOption2 = (
  { xAxisData, series, color, dataViewHeaderFirstTitle, hor },
  unit
) => {
  let seriesData = []
  for (let keyname in series) {
    if (keyname != 'months') {
      seriesData.push({
        name: keyname,
        type: 'bar',
        stack: 'ad',
        barMaxWidth: '16',
        emphasis: {
          focus: 'series',
        },
        data: series[keyname],
      })
    }
  }

  let months = series['months']
  return {
    color: color,
    legend: { right: '50' }, //颜色提示
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = hor ? opt.yAxis[0].data : opt.xAxis[0].data //['SubCore', 'RCC', ..]
          var series = opt.series //
          const seriesData = series[0].data
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${dataViewHeaderFirstTitle}</td>` +
            `<td>Budget</td>` +
            // `<td>总计</td>` +
            '</tr>'
          // 表格题  先name再data

          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              '<td>' +
              numberWithCommas(seriesData[i]) +
              '</td>' +
              '</tr>'
          }

          table += '</tbody></table>'
          return table
        }),
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: hor
      ? [
          {
            name: formatUnit(unit) + '(RMB)',
            type: 'value',
            nameLocation: 'middle',
            nameGap: 25,
          },
        ]
      : [
          {
            type: 'category',
            data: xAxisData,
          },
        ],
    yAxis: hor
      ? [
          {
            type: 'category',
            data: xAxisData,
          },
        ]
      : [
          {
            name: formatUnit(unit) + '(RMB)',
            type: 'value',
          },
        ],
    series: seriesData,
  }
}

export const getTrendPieChartsOption3 = (data, config, unit) => {
  const { color, inlabel, xname } = config //todo 外圈设定年限文案

  let seriesData1 = []

  data.forEach((i) => {
    seriesData1.push({ name: i[xname], value: formatNum(i.budgetamount, unit, 0) })
  })

  return {
    color: color ? color : themecolor,
    animation: false,
    title: {
      subtext: formatUnit(unit) + '(RMB)',
      left: 'left',
    },
    toolbox: {
      feature: {
        dataView: {
          lang: ['数据视图', '关闭', '导出Excel'],
          optionToContent: (opt) => {
            var series = opt.series
            // 表格头  先name再data
            var table =
              '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
              `<td>${xname}</td>` +
              `<td>Budget</td>` +
              '</tr>'
            // 表格题  先name再data
            const namearr = series[0].data
            for (var i = 0, l = namearr.length; i < l; i++) {
              table +=
                '<tr>' +
                '<td>' +
                namearr[i].name +
                '</td>' +
                `<td>${numberWithCommas(namearr[i].value)}${formatUnit(unit)}</td>` +
                '</tr>'
            }
            table += '</tbody></table>'
            return table
          },
          contentToOption,
        },
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        let { name, percent, value } = params

        return `${name} :   ${numberWithCommas(value)}${formatUnit(unit)}   (${percent}%)`
      },
    },
    legend: {
      // selected:getLegendSelected(seriesData1.map(i=>{ return i.name}),checkAllVal),
      // x: 'bottom',
      bottom: 0,
      animation: false,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
      tooltip: {
        axisPointer: {
          animation: false,
        },
      },
    },
    series: [
      // {
      //   // silent: true,
      //   itemStyle: {},
      //   emphasis: {
      //     disabled: false,
      //     scale: false,
      //     // itemStyle:{
      //     //   color:['#F1F1F1','#F00'],
      //     // }
      //   },
      //   // color: ['rgb(84,112,198)','rgb(145,204,117)','rgb(250,200,88)','rgb(238,102,102)'], //蓝 绿 黄
      //   // color: ['#557bd5','#99e086','#ffdc6d'],
      //   legendHoverLink: false,
      //   name: inlabel,
      //   type: 'pie',
      //   selectedMode: 'single',
      //   // radius: [0, '0%'],
      //   label: {
      //     // position: 'inner',
      //     fontSize: 10,
      //     formatter: function (params) {
      //       const { value } = params
      //       return value
      //     },
      //   },
      //   labelLine: {
      //     show: true,
      //   },
      //   data: seriesData1,
      // },
      {
        // silent: true,
        itemStyle: {},
        emphasis: {
          disabled: false,
          scale: false,
          // itemStyle:{
          //   color:['#F1F1F1','#F00'],
          // }
        },
        // color: ['rgb(84,112,198)','rgb(145,204,117)','rgb(250,200,88)','rgb(238,102,102)'], //蓝 绿 黄
        // color: ['#557bd5','#99e086','#ffdc6d'],
        legendHoverLink: false,
        name: inlabel,
        type: 'pie',
        radius: ['70%', '0%'],
        selectedMode: 'single',

        // radius: [0, '0%'],
        label: {
          fontSize: 10,
          normal: {
            formatter: '{b}:    {d}%',
          },
        },
        labelLine: {
          show: true,
        },
        data: seriesData1,
      },
    ],
  }
}
export const getTrendPieChartsOption = (data, config, unit) => {
  const {
    color,
    color1,
    outlabel,
    inlabel,
    checkAllVal,
    dataViewHeaderFirstTitle = 'Division',
    xname,
  } = config //todo 外圈设定年限文案

  let seriesData1 = []

  data.forEach((i) => {
    seriesData1.push({ name: i[xname], value: formatNum(i.budgetamount, unit, 0) })
  })

  return {
    color: color ? color : themecolor,
    animation: false,
    title: {
      subtext: formatUnit(unit) + '(RMB)',
      left: 'left',
    },
    toolbox: {
      feature: {
        dataView: {
          lang: ['数据视图', '关闭', '导出Excel'],
          optionToContent: (opt) => {
            var series = opt.series
            console.log(series, '???...')
            // 表格头  先name再data
            var table =
              '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
              `<td>${dataViewHeaderFirstTitle}</td>` +
              `<td>Budget</td>` +
              '</tr>'
            // 表格题  先name再data
            const namearr = series[0].data
            for (var i = 0, l = namearr.length; i < l; i++) {
              table +=
                '<tr>' +
                '<td>' +
                namearr[i].name +
                '</td>' +
                `<td>${numberWithCommas(namearr[i].value)}</td>` +
                '</tr>'
            }
            table += '</tbody></table>'
            return table
          },
          contentToOption,
        },
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        let { name, percent, value } = params

        return `${name}:${numberWithCommas(value)}   (${percent}%)`
      },
    },
    legend: {
      // selected:getLegendSelected(seriesData1.map(i=>{ return i.name}),checkAllVal),
      // x: 'bottom',
      bottom: 0,
      animation: false,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
      tooltip: {
        axisPointer: {
          animation: false,
        },
      },
    },
    series: [
      // {
      //   // silent: true,
      //   itemStyle: {},
      //   emphasis: {
      //     disabled: false,
      //     scale: false,
      //     // itemStyle:{
      //     //   color:['#F1F1F1','#F00'],
      //     // }
      //   },
      //   // color: ['rgb(84,112,198)','rgb(145,204,117)','rgb(250,200,88)','rgb(238,102,102)'], //蓝 绿 黄
      //   // color: ['#557bd5','#99e086','#ffdc6d'],
      //   legendHoverLink: false,
      //   name: inlabel,
      //   type: 'pie',
      //   selectedMode: 'single',
      //   // radius: [0, '0%'],
      //   label: {
      //     // position: 'inner',
      //     fontSize: 10,
      //     formatter: function (params) {
      //       const { value } = params
      //       return value
      //     },
      //   },
      //   labelLine: {
      //     show: true,
      //   },
      //   data: seriesData1,
      // },
      {
        // silent: true,
        itemStyle: {},
        emphasis: {
          disabled: false,
          scale: false,
          // itemStyle:{
          //   color:['#F1F1F1','#F00'],
          // }
        },
        // color: ['rgb(84,112,198)','rgb(145,204,117)','rgb(250,200,88)','rgb(238,102,102)'], //蓝 绿 黄
        // color: ['#557bd5','#99e086','#ffdc6d'],
        legendHoverLink: false,
        name: inlabel,
        type: 'pie',
        selectedMode: 'single',
        // radius: [0, '0%'],
        label: {
          position: 'inner',
          fontSize: 10,
        },
        labelLine: {
          show: true,
        },
        data: seriesData1,
      },
    ],
  }
}
export const getTrendPieChartsOption2 = (data, config, unit) => {
  const { color, inlabel, xname } = config //todo 外圈设定年限文案

  let seriesData1 = []

  data.forEach((i) => {
    seriesData1.push({ name: i[xname], value: formatNum(i.budgetamount, unit, 0) })
  })

  return {
    color: color ? color : themecolor,
    animation: false,
    title: {
      subtext: formatUnit(unit) + '(RMB)',
      left: 'left',
    },
    toolbox: {
      feature: {
        dataView: {
          lang: ['数据视图', '关闭', '导出Excel'],
          optionToContent: (opt) => {
            var series = opt.series
            // 表格头  先name再data
            var table =
              '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
              `<td>${xname}</td>` +
              `<td>Budget</td>` +
              '</tr>'
            // 表格题  先name再data
            const namearr = series[0].data
            for (var i = 0, l = namearr.length; i < l; i++) {
              table +=
                '<tr>' +
                '<td>' +
                namearr[i].name +
                '</td>' +
                `<td>${numberWithCommas(namearr[i].value)}${formatUnit(unit)}</td>` +
                '</tr>'
            }
            table += '</tbody></table>'
            return table
          },
          contentToOption,
        },
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        let { name, percent, value } = params

        return `${name}  : ${numberWithCommas(value)}${formatUnit(unit)}    (${percent}%)`
      },
    },
    legend: {
      bottom: 0,
      type: 'scroll',
      animation: false,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
      tooltip: {
        axisPointer: {
          animation: false,
        },
      },
    },
    series: [
      // {
      //   // silent: true,
      //   itemStyle: {},
      //   emphasis: {
      //     disabled: false,
      //     scale: false,
      //     // itemStyle:{
      //     //   color:['#F1F1F1','#F00'],
      //     // }
      //   },
      //   // color: ['rgb(84,112,198)','rgb(145,204,117)','rgb(250,200,88)','rgb(238,102,102)'], //蓝 绿 黄
      //   // color: ['#557bd5','#99e086','#ffdc6d'],
      //   legendHoverLink: false,
      //   name: inlabel,
      //   type: 'pie',
      //   selectedMode: 'single',
      //   // radius: [0, '0%'],
      //   label: {
      //     // position: 'inner',
      //     fontSize: 10,
      //     formatter: function (params) {
      //       const { value } = params
      //       return value
      //     },
      //   },
      //   labelLine: {
      //     show: true,
      //   },
      //   data: seriesData1,
      // },
      {
        // silent: true,
        itemStyle: {},
        emphasis: {
          disabled: false,
          scale: false,
          // itemStyle:{
          //   color:['#F1F1F1','#F00'],
          // }
        },
        radius: ['70%', '0%'],
        // color: ['rgb(84,112,198)','rgb(145,204,117)','rgb(250,200,88)','rgb(238,102,102)'], //蓝 绿 黄
        // color: ['#557bd5','#99e086','#ffdc6d'],
        legendHoverLink: false,
        name: inlabel,
        type: 'pie',
        selectedMode: 'single',
        // radius: [0, '0%'],
        label: {
          fontSize: 10,

          normal: {
            formatter: '{b}:   {d}%',
          },
        },
        labelLine: {
          show: true,
        },
        data: seriesData1,
      },
    ],
  }
}

export const getTrendBarChartsOption_hor = (data, config, unit) => {
  const { year } = config //todo 外圈设定年限文案
  //内圈是大年 2022  外圈是小年 2021
  const [curyear, preyear] = config.years
  const yAxisData = data.filter((i) => i.year === curyear).map((i) => i.axis)
  const seriesData = []

  // seriesData[0] = data.filter((i) => i.year === preyear).map((i) => formatMillion(i.budget))
  seriesData[1] = data.filter((i) => i.year === curyear).map((i) => formatNum(i.budget, unit, 2))
  seriesData[0] = new Array(yAxisData.length).fill(0)
  data
    .filter((i) => i.year === preyear)
    .forEach((i) => {
      const index = yAxisData.indexOf(i.axis)
      seriesData[0][index] = formatNum(i.budget, unit, 2)
    })
  return {
    color: themecolor_two,
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var series = opt.series
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Year</td>' +
            `<td>${series[0].name} Budget</td>` +
            `<td>${series[0].name + ' Share'}</td>` +
            `<td>${series[1].name} Budget</td>` +
            `<td>${series[1].name + ' Share'}</td>` +
            '</tr>'
          // 表格题  先name再data
          const namearr = opt.yAxis[0].data
          const sum22 = series[0].data.reduce((pre, cur) => {
            pre += cur
            return pre
          }, 0)
          const sum21 = series[1].data.reduce((pre, cur) => {
            pre += cur
            return pre
          }, 0)
          for (var i = 0, l = namearr.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              namearr[i] +
              '</td>' +
              `<td>${series[0].data[i]}</td>` +
              `<td>${((series[0].data[i] / sum22) * 100)?.toFixed(1)}%</td>` +
              `<td>${series[1].data[i]}</td>` +
              `<td>${((series[1].data[i] / sum21) * 100)?.toFixed(1)}%</td>` +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    title: {
      subtext: 'Mil(RMB)',
      left: 'left',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      right: '50',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
    },
    series: [
      {
        name: curyear,
        type: 'bar',
        barWidth: '16',
        data: seriesData[1],
      },
      {
        name: preyear,
        type: 'bar',
        barWidth: '16',
        data: seriesData[0],
      },
    ],
  }
}

export const createargs = (args) => {
  const { groups, filters } = args
  return {
    fields: ['budget'],
    groups,
    filters,
    sorts: [
      {
        field: 'year',
        type: 'ASC',
      },
      {
        field: 'division',
        type: 'ASC',
      },
    ],
  }
}

export const argscreator = (baseconfig) => {
  return ({ yearVal, division, se }) => {
    const filters = baseconfig.filters

    if (yearVal) {
      const index = filters.findIndex((i) => i.field === 'year')
      filters[index].values = yearVal
    }
    if (division) {
      const index = filters.findIndex((i) => i.field === 'division')
      filters[index].values = division
    }
    if (se) {
      const index = filters.findIndex((i) => i.field === 'se')
      if (Array.isArray(se)) {
        filters[index].values = se
      } else {
        filters[index].values = [se]
      }
    }
    return baseconfig
  }
}

export const pieconfig1 = {
  //division
  fields: ['budget'],
  groups: ['mediagroup'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2023],
    },
    {
      field: 'month',
      operator: 'IN',
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
    {
      field: 'productline',
      operator: 'IN',
      values: [2023],
    },
    {
      field: 'division',
      operator: 'IN',
      values: divisionOption,
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'division',
      type: 'ASC',
    },
  ],
}
export const pieconfig2 = {
  //se
  fields: ['budget'],
  groups: ['year', 'se'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'division',
      operator: 'IN',
      values: divisionOption,
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'se',
      type: 'ASC',
    },
  ],
}
export const pieconfig3 = {
  //product
  fields: ['budget'],
  groups: ['year', 'product'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'Product',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'se',
      operator: 'EQUALS',
      values: ['BAIDU'],
    },
    {
      field: 'division',
      operator: 'IN',
      values: divisionOption,
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'product',
      type: 'ASC',
    },
  ],
}

export const pieconfig4 = {
  //fbp - axios
  fields: ['budget'],
  groups: ['year', 'axis'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'division',
      operator: 'EQUALS',
      values: ['FBP'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'axis',
      type: 'ASC',
    },
  ],
}
export const pieconfig5 = {
  //fbp - product
  fields: ['budget'],
  groups: ['year', 'product'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'product',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'division',
      operator: 'EQUALS',
      values: ['FBP'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'product',
      type: 'ASC',
    },
  ],
}
export const pieconfig6 = {
  //wfj - axis
  fields: ['budget'],
  groups: ['year', 'axis'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'division',
      operator: 'EQUALS',
      values: ['WFJ'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'axis',
      type: 'ASC',
    },
  ],
}
export const pieconfig7 = {
  //wfj - product
  fields: ['budget'],
  groups: ['year', 'product'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'product',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'division',
      operator: 'EQUALS',
      values: ['WFJ'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'product',
      type: 'ASC',
    },
  ],
}

export const pieconfig8 = {
  //fa
  fields: ['budget'],
  groups: ['year', 'product'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'product',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'division',
      operator: 'EQUALS',
      values: ['FA'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'product',
      type: 'ASC',
    },
  ],
}
export const barhorconfig = {
  // axios
  fields: ['budget'],
  groups: ['year', 'axis'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    // {
    //   field: 'division',
    //   operator: 'EQUALS',
    //   values: ['FBP'],
    // },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'axis',
      type: 'ASC',
    },
  ],
}

export const getLineAreaOption = ({ xdata, seriesData, title }, unit) => {
  return {
    title: {
      text: title,
      left: 'center',
      textStyle: {
        opacity: 0.6,
        fontSize: 14,
        color: 'rgb(0, 0, 0)',
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      show: false,
      data: xdata,
    },
    yAxis: {
      show: false,
      type: 'value',
    },
    series: [
      {
        data: seriesData,
        type: 'line',
        showSymbol: false,
        lineStyle: {
          width: 0,
        },
        smooth: true,
        areaStyle: {
          opacity: 0.8,
          color: 'rgb(153, 102, 204)',
        },
      },
    ],
  }
}

export const getTrendLineOption = ({ m0data, m1data, m0, m1 }, unit) => {
  // const series1 = preyeardata.map((i) => i.si)
  const series1 = new Array(12).fill(0)
  m0data.forEach((i) => {
    const index = i.month - 1
    series1[index] = i.budgetamount
  })

  // debugger
  const series2 = m1data.map((i) => i.budgetamount)

  const xAxisData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => formatmonth(i))

  return {
    color: themecolor_two,
    tooltip: {
      trigger: 'axis',
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          console.log(opt)
          var axisData = opt.xAxis[0].data
          var series = opt.series
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Month</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map((j) => `<td>${j.data[i] ? numberWithCommas(j.data[i]) : 0}</td>`)
                .join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: {
      data: [m0 + '', m1 + ''],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    // toolbox: {
    // feature: {
    //   saveAsImage: {},
    // },
    // },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
    },
    yAxis: {
      name: formatUnit(unit) + '(RMB)',
      type: 'value', //111111111
    },
    series: [
      {
        name: m1,
        type: 'line',
        data: series2,
        smooth: true,
      },
      {
        name: m0,
        type: 'line',
        smooth: true,
        data: series1,
      },
    ],
  }
}
