import Card from '../../components/Card'
import { useState } from 'react'
import TrendPieHor from './trend-bar-hor'
import CombineDate from './combineDate'
import TrendDivisionAndMonth from './trendDivisionAndMonth'
import TrendPie from './trend-pie'
import TrendPie2 from './trend-pie2'
import { Select } from 'antd'
import { connect } from 'react-redux'
import styles from './index.module.less'
import { useNavigate } from 'react-router-dom'

import { pieconfig4, pieconfig5, pieconfig9, argscreator } from './util'
import { themecolor, themecolor1, themecolor_pie2, themecolor_pie21 } from '@/common/vars'

const { wrap } = styles
const Fbp = (props) => {
  const navigate = useNavigate()

  const { currentYear, preYear, yearsOpt, unit } = props
  const [years, setYears] = useState([currentYear, preYear])
  const [year, setYear] = useState(currentYear)
  // const options = years.map((i) => ({ label: i, value: i }))

  return (
    <div className={wrap}>
      <div className="breadcrumb">
        <span className="root" onClick={() => navigate('/home/index')}>
          Finance Spending
        </span>{' '}
        / <span className="current">Spending by FBP</span>
      </div>
      <Card
        title="FBP Trend"
        headerFilter={
          <>
            Selected Period：
            <Select
              getPopupContainer={(n) => n.parentNode}
              style={{ width: '120px' }}
              options={yearsOpt}
              onChange={(val) => setYear(val)}
              defaultValue={year}
            />
          </>
        }
      >
        <TrendDivisionAndMonth
          color={themecolor_pie2}
          year={year}
          unit={unit}
          type="FBP"
          style={{ width: '100%' }}
        ></TrendDivisionAndMonth>
      </Card>
      <Card
        title="Spending Month-on-Month"
        headerLine={true}
        headerFilter={
          <CombineDate
            onChange={(val) => {
              setYears(val)
            }}
            val={years}
          />
        }
        style={{ marginTop: '16px' }}
      >
        <div className="line" style={{ marginBottom: '22px' }}>
          <div className="line-1-left">
            <TrendPie2
              title="By Axis"
              unit={unit}
              years={years}
              argsfn={argscreator(pieconfig9)}
              color={themecolor_pie2}
              color1={themecolor_pie21}
            ></TrendPie2>
          </div>
          <div className="line-1-right">
            <TrendPie
              title="By Product"
              years={years}
              argsfn={argscreator(pieconfig5)}
              color={themecolor}
              color1={themecolor1}
            ></TrendPie>
          </div>
        </div>
      </Card>
    </div>
  )
}
export default connect((state) => ({
  currentYear: state.years[state.years.length - 1],
  preYear: state.years[state.years.length - 2],
  yearsOpt: state.years.map((i) => ({ label: i, value: i })),
  unit: state.unit,
}))(Fbp)
