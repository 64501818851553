import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Radio, Select } from 'antd'
import { getBudgetSum3 } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getMonthTrendBarChartsOption, formatmonth, formatMillion } from './util'
import { formatNum } from '@/common/util'
import { set } from 'lodash-es'
import { Empty } from 'antd'
import { divisionOption, sortOptions } from '@/common/vars'
export default function Crossbar3(props) {
  const [sortVal, setSortVal] = useState('budgetamount|ASC')
  const {
    division,
    years,
    color,
    months,
    productLine,
    unit,
    title,
    reverse,
    hor = true,
    mediaChannel,
    media,
    mediaFormat,
    categorycorp,
    mediaGroup,
    setProductLine,
    campaign,
    setcampaign,
    get,
    yearsopt,
  } = props

  const radioOption = [
    {
      label: 'Campaign',
      value: 'campaignname',
    },
    {
      label: 'Product',
      value: 'productline',
    },
  ]
  const [group, setGroup] = useState(radioOption[0].value)
  const onChange2 = ({ target: { value } }) => {
    localStorage.setItem('barGroup', value)
    if (value === 'productline') setSortVal('budgetamount|DESC')

    handleSortChange('budgetamount|DESC')
    setGroup(value)
  }
  // const [group, setGroup] = useState(type)
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  let checkGrouop = undefined
  const changePieName = (val) => {
    let g = localStorage.getItem('barGroup')
    checkGrouop = val === checkGrouop ? undefined : val

    if (g === 'productline') setProductLine(checkGrouop ? [checkGrouop] : [])
    else setcampaign(checkGrouop ? [checkGrouop] : [])
  }

  const { updateCharts } = useCharts(trend, { changePieName })
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const [sort, setSort] = useState([
    {
      field: 'budgetamount',
      type: 'ASC',
    },
  ])

  useEffect(() => {
    localStorage.setItem('barGroup', group)
    let filters = []
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })

    if (!mediaChannel.includes('all'))
      filters.push({
        field: 'mediaChannel',
        operator: 'IN',
        values: mediaChannel,
      })

    if (!mediaFormat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaFormat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })

    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })

    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })

    if (!campaign.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaign,
      })

    if (!division.includes('all'))
      filters.push({
        field: 'division',
        operator: 'IN',
        values: division,
      })

    if (!categorycorp.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: categorycorp,
      })

    getBudgetSum3({
      group,
      fields: ['budget', 'minyear', 'minmonth', 'months'],
      groups: [group],
      filters,
      sorts: sort,
    }).then((res) => {
      if (reverse) {
        res.reverse()
      }
      if (!Array.isArray(res)) {
        return setEmpty(true)
      }
      setEmpty(false)
      const xAxisData = [...new Set(res.map((i) => i[group]))]
      const series = res.reduce((pre, cur) => {
        let name = 'Budget'
        if (!pre[name]) pre[name] = []
        if (!pre['months']) pre['months'] = []
        pre[name].push(formatNum(cur.budgetamount, unit, 0))
        pre['months'].push(cur['months'] || '--')
        return pre
      }, {})

      updateCharts(
        getMonthTrendBarChartsOption(
          {
            xAxisData,
            series,
            color,
            dataViewHeaderFirstTitle: group,
            hor,
          },
          unit
        )
      )
    })
  }, [years, months, unit, get, sort, group])

  const handleSortChange = (val) => {
    let sort2 = []
    if (val) {
      let val_ = val.split('|')
      sort2.push({
        field: val_[0],
        type: val_[1],
      })
    }
    setSort(sort2)
    return setSortVal(val)
  }
  // useEffect(() => {

  // }, [group])

  return (
    <div className="trend-wrap">
      <div className="title">{title + (group === 'productline' ? '  Product' : 'Campaign')}</div>
      <div className="tool">
        <div style={{ width: '170px' }}>
          <Radio.Group
            options={radioOption}
            onChange={onChange2}
            value={group}
            size="small"
            optionType="button"
          />
        </div>
        <div style={{ width: '240px' }}>
          <span>排序：</span>
          <Select
            size="small"
            placeholder="请选择"
            options={
              group === 'campaignname'
                ? sortOptions
                : sortOptions.filter((i) => {
                    return i.value.includes('budgetamount')
                  })
            }
            value={sortVal}
            onChange={handleSortChange}
            style={{ width: '190px' }}
          />
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          width: 'calc(100%)',
          height: '430px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
