import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Radio, Select } from 'antd'
import { getPerformanceSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getMonthTrendBarChartsOption } from './util'
import { formatNum } from '@/common/util'

import { Empty } from 'antd'
import { divisionOption } from '@/common/vars'
export default function Crossbar3(props) {
  const {
    division,
    years,
    color,
    months,
    unit,
    title,
    category,
    get,
    productLine,
    campaignname,
    channel,
    media,
    mediaformat,
    setProductLine,
    setCampaignname,
    setField1,
    yearsopt,
    asset,
  } = props
  const [group, setGroup] = useState('campaignname')
  const radioOption = [
    {
      label: 'Campaign',
      value: 'campaignname',
    },
    {
      label: 'Product',
      value: 'productline',
    },
  ]
  const onChange2 = ({ target: { value } }) => {
    localStorage.setItem('detailGroup', value)
    setGroup(value)
  }
  let checkGrouop = undefined
  const changePieName = (val) => {
    let g = localStorage.getItem('detailGroup')
    checkGrouop = val === checkGrouop ? undefined : val
    if (g === 'productline') setProductLine(checkGrouop ? [checkGrouop] : [])
    else setCampaignname(checkGrouop ? [checkGrouop] : [])
  }
  const [field, setfield] = useState('impression')
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend, { changePieName })
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  useEffect(() => {
    setfield('impression')
    setField1('impression')
  }, [channel])
  const fieldOpt = [
    {
      label: 'Impression',
      value: 'impression',
      tag: [
        'all',
        'WeChat Moments RTB',
        'WeChat Channel Feeds',
        'Red Feeds',
        'Union Pay Programmatic',
        'Baidu App Opening Page',
      ],
    },
    {
      label: 'Click',
      value: 'click',
      tag: [
        'all',
        'WeChat Moments RTB',
        'WeChat Channel Feeds',
        'Red Feeds',
        'Union Pay Programmatic',
        'Baidu App Opening Page',
      ],
    },
    {
      label: 'Traffic',
      value: 'traffic',
      tag: ['all', 'WeChat Moments RTB', 'WeChat Channel Feeds', 'Red Feeds'],
    },
    {
      label: 'Order',
      value: 'order',
      tag: ['all', 'WeChat Moments RTB', 'WeChat Channel Feeds', 'Red Feeds'],
    },
    {
      label: 'Revenue',
      value: 'revenue',
      tag: ['all', 'WeChat Moments RTB', 'WeChat Channel Feeds', 'Red Feeds'],
    },
    {
      label: 'Cost',
      value: 'cost',
      tag: [
        'all',
        'WeChat Moments RTB',
        'WeChat Channel Feeds',
        'Red Feeds',
        'Union Pay Programmatic',
        'Baidu App Opening Page',
      ],
    },

    {
      label: 'Engagement-Wechat',
      value: 'engagementwechat',
      tag: ['WeChat Moments RTB', 'WeChat Channel Feeds'],
    },
    {
      label: 'YS T+0 Revenue',
      value: 't0ysrevenue',
      tag: ['WeChat Moments RTB'],
    },
    {
      label: 'YS T+7 Revenue',
      value: 't7ysrevenue',
      tag: ['WeChat Moments RTB'],
    },
    {
      label: 'YS T+15 Revenue',
      value: 't15ysrevenue',
      tag: ['WeChat Moments RTB'],
    },
    // {
    //   label: 'YS T+0 ROI',
    //   value: 't0ysrevenuema',
    //   tag: ['WeChat Moments RTB', 'WeChat Channel Feeds'],
    // },
    // {
    //   label: 'YS T+7 ROI',
    //   value: 't7ysrevenuema',
    //   tag: ['WeChat Moments RTB', 'WeChat Channel Feeds'],
    // },
    // {
    //   label: 'YS T+15 ROI',
    //   value: 't15ysrevenuema',
    //   tag: ['WeChat Moments RTB', 'WeChat Channel Feeds'],
    // },
    {
      label: 'Engagement-POST',
      value: 'engagementpost',
      tag: ['Red Feeds'],
    },
  ]
  const capitalizeFirstLetter = (str) => {
    return fieldOpt.filter((it) => {
      return it.value === str
    })[0]?.label
  }
  useEffect(() => {
    localStorage.setItem('detailGroup', group)

    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Paid Search'],
      },
      {
        field: group,
        operator: 'NOT_IN',
        values: ['', ' ', '-'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!channel.includes('all'))
      filters.push({
        field: 'mediachannel',
        operator: 'IN',
        values: channel,
      })
    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })
    getPerformanceSum({
      title: '环形图',
      group,
      fields: [field],
      groups: [group],
      filters,
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      let res2 = res.sort((a, b) => b[field] - a[field])
      let data = res2.map((i) => {
        return { name: i[group], value: formatNum(i[field], unit, 0) }
      })

      updateCharts(
        getMonthTrendBarChartsOption(
          {
            data,
            color,
            dataViewHeaderFirstTitle: group === 'productline' ? '  Product' : 'Campaign',
            field: capitalizeFirstLetter(field),
          },
          unit
        )
      )
    })
  }, [years, months, division, unit, field, get, group])

  return (
    <div className="trend-wrap">
      <div className="title">
        {capitalizeFirstLetter(field) +
          '  by  ' +
          (group === 'productline' ? '  Product' : 'Campaign')}
      </div>
      <div className="tool">
        <div style={{ width: '170px' }}>
          <Radio.Group
            options={radioOption}
            onChange={onChange2}
            value={group}
            size="small"
            optionType="button"
          />
        </div>

        <div>
          <Select
            options={fieldOpt.filter((i) => {
              return channel.length == 1 ? i.tag.includes(channel[0]) : i.tag.includes('all')
            })}
            size="small"
            value={field}
            onChange={(val) => {
              setfield(val)
              setField1(val)
            }}
            style={{ width: '240px' }}
          />
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          width: 'calc(100%)',
          height: '280px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
